import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`A propos`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Étudiant en Licence Pro Conception et Développement d'Applications Distribuées`}</p>
    </blockquote>
    <p>{`21 ans et basé à Strasbourg, je suis disponible lundi, mardi et mercredi dans le cadre de mon alternance, jusqu'à fin juin et ensuite à temps plein.`}</p>
    <p>{`Vous retrouverez sur ce site la liste de mes projets en cours ou terminés ainsi que mes réseaux sociaux et un résumé de mes compétences.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      