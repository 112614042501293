import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Demandez-moi`}</h2>
    <p><a parentName="p" {...{
        "href": "mailto:perso@mathieuv.pro"
      }}>{`Contactez-moi par mail`}</a>{` où suivez mon travail sur d'autres plateformes : `}<a parentName="p" {...{
        "href": "https://www.github.com/mathieu900v"
      }}>{`GitHub`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.linkedin.com/in/mathieuvoyerpro/"
      }}>{`LinkedIn`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      