import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projets`}</h2>
    <ProjectCard title="Randonnée à Dutt" link="https://pierre-even.itch.io/randonnee-a-dutt" bg="linear-gradient(to right, #b5179e 0%, #7209b7 100%)" mdxType="ProjectCard">
	Jeu sérieux réalisé en 4 jours basé sur des travaux de recherches sur la randonnée en Allemagne au XIXe siècle.
      <pre><code parentName="pre" {...{}}>{`> Unreal Engine, C++
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Postier de Fakarava" link="https://pierre-even.itch.io/le-postier-de-fakarava" bg="linear-gradient(to right, #560bad 0%, #480ca8 100%)" mdxType="ProjectCard">
	Jeu sur le thème de Fakarava (DOM/TOM) réalisé en 48h lors d'une GameJam. 
      <pre><code parentName="pre" {...{}}>{`> Unreal Engine, C++
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Python Cozmo" link="https://github.com/mathieu900v/Python-Cozmo" bg="linear-gradient(to right, #3a0ca3 0%, #3f37c9 100%)" mdxType="ProjectCard">
	Solution complète sous forme de site Web pour aider des jeunes à développer avec le robot Cozmo
      <pre><code parentName="pre" {...{}}>{`> Raspberry Pi, Ubuntu-server, Django, SQL, Web
`}</code></pre>
    </ProjectCard>
    <ProjectCard title="Black Spirit" link="#" bg="linear-gradient(to right, #b5179e 0%, #3a0ca3 100%)" mdxType="ProjectCard">
	Site E-Commerce interactif d'une marque de vêtements
      <pre><code parentName="pre" {...{}}>{`> React, PostgreSQL, Oracle Cloud
`}</code></pre>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      